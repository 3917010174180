import "./SectionTitle.css";

function SectionTitle({ text }) {
  return (
    <div className="sectionTitle">
      <p>{text}</p>
    </div>
  );
}

export default SectionTitle;
