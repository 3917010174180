import { Modal } from "@mui/material";
import "./CSS/ProfileModal.css";
import emailIcon from "./emailIcon.png";

function ProfileModal({ focus, open, handleClose }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{
        style: {
          opacity: 0.15,
        },
      }}
    >
      <div className="profileModal">
        <button className="closeButton" onClick={handleClose}>
          <p>x</p>
        </button>
        <div className="gridContainer">
          <div className="leftGrid">
            <img src={focus.image} alt={focus.alt} className="profileImage" />
            <a href={`mailto:${focus.email}`} className="emailButton">
              <img src={emailIcon} alt="email icon" />
              <p>email</p>
            </a>
          </div>
          <div className="rightGrid">
            <h2 className="profileName">{focus.name}</h2>
            <p className="position">{focus.position}</p>
            <p className="background">
              <span>Personal Background:</span> {focus.bio}
            </p>
            <p className="research">
              <span>Research Interests:</span> {focus.research}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ProfileModal;
