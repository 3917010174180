import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import MenuIcon from "@mui/icons-material/Menu";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LinkedButton from "../buttons/LinkedButton/LinkedButton";
import ChatIcon from "./chatIcon.png";
import "./CSS/Header.css";
import "./CSS/Header_mobile.css";

function Header() {
  const location = useLocation();
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -30;
    if (location.pathname === "/") {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    } else {
      window.scrollTo({ top: yCoordinate + yOffset });
    }
  };

  const handleMobileDropdown = () => {
    setShowMobileDropdown(!showMobileDropdown);
  };

  useEffect(() => {
    setShowMobileDropdown(false);
  }, [location]);

  return (
    <div className="navContainer">
      <nav className="navbar-desktop">
        <div className="labLogoContainer">
          <img src={ChatIcon} alt="chat icon" className="chatIcon" />
          <div className="labLogoTextContainer">
            <p className="labLogoText-Header">
              Hopfer{" "}
              <span className="labLogoText-Header-Span">Communication</span> Lab
            </p>
            <p className="labLogoText-Subeader">
              at University of California, Irvine
            </p>
          </div>
        </div>
        <div className="navLinksContainer">
          <Link to="/" className="navLink">
            about
          </Link>
          <HashLink
            smooth
            to="/#research"
            scroll={(el) => scrollWithOffset(el)}
            className="navLink"
          >
            research
          </HashLink>
          <Link to="/team" className="navLink">
            team
          </Link>
          <Link to="/teaching" className="navLink">
            teaching
          </Link>
          <Link to="/publications" className="navLink">
            publications
          </Link>
          <Link to="/news" className="navLink">
            news
          </Link>
        </div>
        <div className="socialIconsContainer">
          <a
            href="https://twitter.com/HopferLab"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterIcon fontSize="medium" className="socialIcon" />
          </a>
          <a
            href="https://www.linkedin.com/in/suellen-hopfer-8ab57b28"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon fontSize="medium" className="socialIcon" />
          </a>
        </div>
        <LinkedButton path="/contact" text="contact" size="nav" />
      </nav>
      <nav
        className={
          showMobileDropdown
            ? "navbar-mobile-expanded navbar-mobile"
            : "navbar-mobile"
        }
      >
        <div className="default">
          <div className="labLogoContainer">
            <img src={ChatIcon} alt="chat icon" className="chatIcon" />
            <div className="labLogoTextContainer">
              <p className="labLogoText-Header">
                Hopfer{" "}
                <span className="labLogoText-Header-Span">Communication</span>{" "}
                Lab
              </p>
              <p className="labLogoText-Subeader">
                at University of California, Irvine
              </p>
            </div>
          </div>
          <button className="dropdownButton" onClick={handleMobileDropdown}>
            <MenuIcon sx={{ color: "white" }} className="menuIcon" />
          </button>
        </div>

        {showMobileDropdown && (
          <div className="dropdown">
            <div className="navLinksContainer">
              <Link to="/" className="navLink">
                about
              </Link>
              <HashLink
                smooth
                to="/#research"
                scroll={(el) => scrollWithOffset(el)}
                className="navLink"
              >
                research
              </HashLink>
              <Link to="/team" className="navLink">
                team
              </Link>
              <Link to="/teaching" className="navLink">
                teaching
              </Link>
              <Link to="/publications" className="navLink">
                publications
              </Link>
              <Link to="/news" className="navLink">
                news
              </Link>
            </div>
            <div className="socialIconsContainer">
              <a
                href="https://twitter.com/HopferLab"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon fontSize="large" className="socialIcon" />
              </a>
              <a
                href="https://www.linkedin.com/in/suellen-hopfer-8ab57b28"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon fontSize="large" className="socialIcon" />
              </a>
            </div>
            <LinkedButton path="/contact" text="contact" size="mobileNav" />
          </div>
        )}
      </nav>
    </div>
  );
}

export default Header;
