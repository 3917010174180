import vaccineConfidence from "./Images/vaccineNarratives.png";
import healthDisparities from "./Images/healthDisparities.png";
import climateChange from "./Images/climateChange.jpeg";
import bigData from "./Images/bigData.jpeg";

const researchAreas = [
  {
    title: "Vaccine Confidence",
    researchArea: "vaccineConfidence",
    image: vaccineConfidence,
  },
  {
    title: "Health Disparities",
    researchArea: "healthDisparities",
    image: healthDisparities,
  },
  {
    title: "Climate Change",
    researchArea: "climateChange",
    image: climateChange,
  },
  {
    title: "Big Data",
    researchArea: "bigData",
    image: bigData,
  },
];

export default researchAreas;
