import SectionTitle from "../SectionTitle/SectionTitle";
import "./Mission.css";

function Mission() {
  return (
    <div className="missionComponent">
      <SectionTitle text="Our Mission" />
      <h2>Why Communication Research?</h2>
      <p className="missionStatement">
        We aim to improve public health by addressing communication phenomena.
        Our research involves examining factors influencing vaccine decisions,
        communication about cancer screening, and vaccine misinformation. We are
        committed to leading research efforts to improve the health and lives of
        communities and to prioritize improving the lives of communities
        disproportionately impacted by adverse health outcomes.
      </p>
    </div>
  );
}

export default Mission;
