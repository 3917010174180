import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./CSS/Profile.css";
import ProfileModal from "./ProfileModal/ProfileModal";

function Profile({ profile, focus, open, handleHeadshotClick, handleClose }) {
  return (
    <div className="profileComponent" id={profile.alt}>
      <img className="image" src={profile.image} alt={profile.alt} />
      <p className="name">{profile.name}</p>
      <p className="position">{profile.position}</p>
      <div
        className="moreInfoButton"
        onClick={() => handleHeadshotClick(profile.alt)}
      >
        <p>More Info</p>
        <ArrowRightAltIcon className="arrowIcon" />
      </div>
      <ProfileModal open={open} focus={focus} handleClose={handleClose} />
    </div>
  );
}

export default Profile;
