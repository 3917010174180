import image3 from "./Images/image3.png";
import image1 from "./Images/image1.png";
import image4 from "./Images/image4.png";
import image2 from "./Images/image2.png";
import dreamStudents from "./Images/IMG_4299-1-scaled-panorama.jpeg";
import dreamBanner from "./Images/DREAM-Canvas-Google-Banners-3.png";
import ab617 from "./Images/60d2226eda07b532012455e7_pavWziK0S0rf2XTn6v-5JCjMd04udl3zb4eFpCRPCHtww7PH9WoJD-qce5ud-kjWi2zt0-BrINqMoNAPuMaSfijPJEeqcXaLfXxoxx7cBgsVzoLaKSew9diCTpeu8rtnH3wyXWC6.png";
import mask1 from "./Images/journal.pone.0257428.g001.PNG";
import mask2 from "./Images/journal.pone.0257428.g002.PNG";
import mask3 from "./Images/journal.pone.0257428.g003.PNG";
import mask4 from "./Images/journal.pone.0257428.g004.PNG";
import purpleAirMap from "./Images/purpleAirMap.png";
import vietnameseFamily from "./Images/103437690_1.jpeg";
import purpleAir1 from "./Images/purpleAir1.jpeg";
import purpleAir2 from "./Images/purpleAir2.jpeg";
import wildfire from "./Images/wildfire.png";

export const studies = {
  vaccineConfidence: [
    {
      title:
        "Scaling up the discovery of hesitancy profiles by identifying framing of beliefs toward vaccine confidence",
      description: [
        "This study focused on the discovery of how vaccine hesitancy is framed in Twitter discourse, allowing us to recognize at scale all tweets that evoke any hesitancy framings as well as the stance of the tweet author toward the frame. By categorizing the hesitancy framings that propagate misinformation, address issues of trust in vaccines, or highlight moral issues or civil rights, we were able to empirically recognize their ontological commitments. Ontological commitments of vaccine hesitancy framings coupled with the stance of tweet authors allowed us to identify hesitancy profiles for two most controversial yet effective and underutilized vaccines for which there remains substantial reluctance among the public: the Human Papillomavirus and the COVID-19 vaccines. The discovered hesitancy profiles inform public health messaging approaches to effectively reach Twitter users with promise to shift or bolster vaccine attitudes.",
        "Collaborator & Lead: Sanda Harabagiu, PhD. (University of Texas, Dallas). Student Researcher: Max Weinzierl, UT Dallas Computer Science doctoral student",
      ],
      image: [image3, image1, image4],
    },
    {
      title:
        "Adolescent COVID-19 vaccine decision-making among parents in Southern California",
      description:
        "Parents uncertain about vaccinating their adolescent children expressed low vaccine confidence and high COVID-19 disease risk complacency. Parents who accepted COVID-19 vaccination for their adolescent children expressed high confidence in health authority vaccine recommendations, high perceived COVID-19 risk, and collective responsibility to vaccinate children. Additionally, unique pandemic-related factors of vaccine acceptance included vaccinating for emotional health, resuming social activities, and vaccine mandates. Among parents, 46% were willing to vaccinate their adolescent, 11% were not, and 43% were unsure. Among adolescents, 63% were willing to vaccinate. Factors associated with vaccine uncertainty and acceptability inform health care practitioner, school, community, and public health messaging to reach parents and adolescents.",
      image: image2,
    },
    {
      title:
        "Implementing an HPV Vaccine National Cancer Institute (NCI) Evidence-based cancer control program (EBCCP) at University of Texas (UT) Health Centers in randomized controlled trial (RCT)",
      description: [
        "Collaborator & Lead: Qian Lu, PhD (University of Texas, Hosuton, MD Anderson Cancer Center).",
        "The study uses a randomized controlled trial (RCT) to test an innovative multilevel intervention to increase HPV vaccination rates among young adults. A total of 1200 young adults in Texas, age 18-26 years, who were not previously vaccinated against HPV will be randomly assigned to one of six groups to receive 1) standard CDC information about HPV vaccination (control); 2) video narratives about HPV vaccination; 3) written narratives about HPV vaccination; or (4-6) enhanced access to HPV vaccine at school combined with 4) standard CDC information, 5) video narratives, or 6) written narratives. The two primary outcomes will be the rate of HPV vaccination initiation by 3-month follow-up and rate of HPV vaccination completion by 9-month follow-up.",
      ],
      publications: [
        {
          text: "HPV Vaccine Intent among Adult Women Receiving Care at Community Health Centers",
          url: "https://link.springer.com/article/10.1007/s13187-020-01937-5",
        },
      ],
      video: "https://vimeo.com/313420587",
    },
    {
      title:
        "HPV vaccine hesitancy among Mexican American women attending federally qualified health centers",
      description:
        "This mixed methods study investigated multi-level factors (individual, interpersonal and community level factors) influencing HPV vaccine hesitancy among young adult Mexican American women attending federally qualified health centers (FQHCs) ",
      publications: [
        {
          text: "HPV vaccine delay and refusal among unvaccinated Mexican American young adult women: A qualitative investigation of Mexican-born and US-born HPV vaccine decision narratives.",
          url: "https://doi.org/10.1007/s10865-022-00326-1",
        },
      ],
      video: "https://vimeo.com/313469138",
    },
    {
      title:
        "Implementing and testing a multi-level, multi-component clinical intervention for increasing HPV vaccination among rural health centers",
      description: [
        "Project on hold.",
        "This project will test the relative contribution of a multi-component, multi-level HPV vaccine intervention.",
      ],
      video: "https://vimeo.com/312639452",
    },
    {
      title:
        "CA HPV Roundtable (RT)-statewide effort to increase HPV vaccination rates",
      description: [
        "Student Researchers: UCI Jocelyn Huang",
        "Annual summer parent social media campaign to increase awareness among parents of preteens (5th and 6th graders) to vaccinate against HPV",
        "Healthcare provider communication training to increase the routine recommendation to vaccinate against HPV vaccination among preteens.",
      ],
      links: [
        {
          text: "California HPV Vaccination Roundtable",
          url: "https://www.cahpvroundtable.org/",
        },
      ],
      video: "https://vimeo.com/311807459",
    },
  ],
  healthDisparities: [
    {
      title:
        "Increasing cancer screening through trusted digital family networks among Vietnamese American families",
      description:
        "Raising awareness and engaging family members about the benefits of colorectal cancer (CRC) and cervical cancer prevention (including HPV vaccination and Pap test) among digital family networks. ",
      publications: [
        {
          text: "Let's Chat: Development of a Family Group Chat Cancer Prevention Intervention for Vietnamese Families",
          url: "https://journals.sagepub.com/doi/10.1177/1090198121990389",
        },
        {
          text: "“Let's Chat”: process evaluation of an intergenerational group chat intervention to increase cancer prevention screening among Vietnamese American families",
          url: "https://academic.oup.com/tbm/article-abstract/11/3/891/6027800?redirectedFrom=fulltext",
        },
      ],
      image: vietnameseFamily,
    },
    {
      title: "Narratives of mental health seeking among undocumented students",
      description: [
        "Collaborator & Lead: UCSB Jennifer Kam, PhD. The Communication and Empowerment Collaborative",
        "This study enlists mental health seeking decision stories/narratives from undocumented students. Prototypical mental health counseling narratives grounded in the experiences of undocumented students are translated into scripts and produced as short videos for CAPS mental health services. Plans for the evaluation of the video is in the planning stages.",
      ],
      links: [
        {
          text: "UCI Dream Scholars Program",
          url: "https://ssi.uci.edu/dreamscholars/",
        },
        {
          text: "The Communication and Empowerment Collaborative (Kam Lab)",
          url: "https://cec.comm.ucsb.edu/home",
        },
      ],
      image: [dreamStudents, dreamBanner],
    },
  ],
  climateChange: [
    {
      title:
        "California Air Resource Board (CARB) funded project to work with AB 617 communities on wildfire impacts.",
      description: [
        "This project involves collaborating with Central California Asthma Collaborative (CCAC) in the San Joaquin Valley (SJV) and with Communities for New California (CNC) Education Fund [CNC EF] in the Eastern Coachella Valley (ECV). The research project involves conducting community impact surveys and discussion groups to collect community narratives (i.e., lived experiences) of living with repeat wildfire, smoke and health impacts on a day-to-day basis.",
        "Funding: California Air Resource Board (CARB)",
      ],
      publications: [
        {
          text: "Assessment of Climate Change Sentiment, Engagement and Adaptation through a Community-Based Outreach Campaign and Questionnaire across the United States",
          url: "https://www.mdpi.com/2673-4834/1/1/6",
        },
      ],
      image: ab617,
    },
    {
      title:
        "Anaheim Union High School District (AUHSD) Air Quality Purple Air Sensor Project",
      description: [
        "This project involves installing real-time air quality sensors that measure particulate matter (PM)2.5. This project takes an environmental justice and community citizen science perspective for students to learn about monitoring air quality in their neighborhood and school environment. A STEM curriculum is being developed for 8th and 12th graders to learn about monitoring local, real-time air quality and taking agency.",
        "Collaborators: Patty Hatcher (Health Curriculum Specialist, AIME Program Coordinator) and Jessica Yett (Science Curriculum Specialist)",
      ],
      links: [
        {
          text: "PurpleAir",
          url: "https://map.purpleair.com/1/mAQI/a10/p604800/cC0#11/33.6357/-117.834",
        },
      ],
      image: [purpleAirMap, purpleAir1, purpleAir2],
    },
    {
      title:
        "NSF funded project and in collaboration with UCI and UCLA, public health and computer science",
      description: [
        "This research involves examining big data e.g., social media to understand communication phenomena related to climate change and health impacts. One study currently examines tweets in geographic and temporal proximity to California wildfires across years 2017-2021 to understand the impact of personal experience on linking wildfires with climate change and urging action. ",
        "Collaborators: Wei Wang (UCLA, machine learning expert) and Chen Li (UCI, collaborative tool design expert)",
      ],
      image: wildfire,
    },
  ],
  bigData: [
    {
      title:
        "NSF COVID-19 social media risk perceptions and risk communication: Public response to masks and vaccination",
      description: [
        "Twitter represents a mainstream news source for the American public, offering a valuable vehicle for learning how citizens make sense of pandemic health threats like Covid-19. Masking as a risk mitigation measure became controversial in the US. The social amplification risk framework offers insight into how a risk event interacts with psychological, social, institutional, and cultural communication processes to shape Covid-19 risk perception.",
        "Collaborators: Chen Li, PhD (Computer Science) and Gloria Mark, PhD (Informatics)",
      ],
      publications: [
        {
          text: "The social amplification and attenuation of COVID-19 risk perception shaping mask wearing behavior: A longitudinal twitter analysis",
          url: "https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0257428",
        },
      ],
      image: [mask1, mask2, mask3, mask4],
    },
    {
      title:
        "NSF funded project and in collaboration with UCI and UCLA, public health and computer science",
      description: [
        "This research involves examining big data e.g., social media to understand communication phenomena related to climate change and health impacts. One study currently examines tweets in geographic and temporal proximity to California wildfires across years 2017-2021 to understand the impact of personal experience on linking wildfires with climate change and urging action.",
        "Collaborators: Wei Wang (UCLA, machine learning expert) and Chen Li (UCI, collaborative tool design expert)",
      ],
      image: wildfire,
    },
  ],
};
