export const courses = {
  undergraduate: [
    {
      code: "PUBHLTH 91",
      units: "2-8",
      title: "Disparities in Health Care",
      description:
        "Student participatory course practicing initiation, planning, and coordination of various speakers on the subject of Disparities in Health Care.",
    },
    {
      code: "PUBHLTH 148",
      units: "4",
      title: "Public Health Communication",
      description:
        "Theoretical underpinnings and practical applications of communication sciences in public health practice. Techniques of effective communication, audience segmentation strategies, and principles of public health communication across settings (e.g., healthcare, interpersonal, social media, mass media, community) and across content (e.g., climate change & health, vaccination, diet, mental health, substance use) operating at multiple levels (individual, interpersonal, family, institutional, policy level determinants of health behavior) are covered. Crisis communication, cultural communication and ethics in addition to designing and evaluating public health campaigns are also covered.",
    },
  ],
  graduate: [
    {
      code: "PUBHLTH 223",
      units: "4",
      title: "Risk Communication",
      description:
        "Examines theory and research related to the communication of scientific information in risk communication contexts, risk perceptions, and behavior as related to decision-making under risk.",
    },
    {
      code: "PUBHLTH 242",
      units: "4",
      title: "Theories of Health Communication",
      description:
        "Explores the concepts, constructs, and theories of communication in health and risk contexts. Examines interpersonal, family, organizational, and mediated communicative processes about health care and conditions from a global perspective.",
    },
    {
      code: "PUBHLTH 287",
      units: "4",
      title: "Qualitative Research Methods in Public Health",
      description:
        "General introduction to qualitative research methods for investigating public health questions at various scales from community level to global populations. Emphasizes systematic approaches to the collection, analysis, interpretation of qualitative data.",
    },
    {
      code: "PUBHLTH 291A/B/C",
      units: "2",
      title: "Seminar: Advances and Challenges in Public Health",
      description:
        "Forum for exploring recent advances and challenges in all disciplines of public health research and practice. Features case studies exemplifying the integration of core competencies with cross-cutting interdisciplinary themes of public health.",
    },
    {
      code: "ECO EVO 203A",
      units: "2-12",
      title: "Graduate Tutorial in Ecology and Evolutionary Biology",
      description:
        "Advanced study in areas not represented by formal courses. May involve individual or small group study through reading, discussion, and composition.",
    },
    {
      code: "PED GEN 200G",
      units: "4",
      title: "Hereditary Cancer Counseling",
      description:
        "Issues in genetic counseling for cancer. Cancer biology; genetic mechanisms and environmental influences in carcinogenesis; tumor pathology; cancer gene mapping; epidemiology. Features natural history, diagnosis, prevention, surveillance, and management of heredity cancers. Psychosocial, ethical, legal aspects of cancer risk assessment.",
    },
    {
      code: "MOL BIO 201C",
      units: "2",
      title: "Seminars in Molecular Biology & Biochemistry",
      description:
        "Presentation of research from department laboratories or, when pertinent, of other recent developments.",
    },
  ],
};
