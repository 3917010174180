import Article from "./Article/Article";
import { newsArticles } from "../../constants/newsArticles";
import "./CSS/News.css";

function News() {
  return (
    <div className="newsComponent">
      <h1>Hopfer Lab News</h1>
      <div className="articleContainer">
        {newsArticles.map((article) => {
          return (
            <Article
              title={article.title}
              link={article.link}
              source={article.source}
              intro={article.intro}
              date={article.date}
              image={article.image}
              key={"_" + Math.random().toString(36).substr(2, 9)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default News;
