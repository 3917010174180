import { useState } from "react";
import { useForm } from "@formspree/react";
import { Modal } from "@mui/material";
import CalendlyWidget from "./CalendlyWidget/CalendlyWidget";
import LinkedButton from "../buttons/LinkedButton/LinkedButton";
import "./CSS/Contact.css";
import "./CalendlyWidget/CalendlyWidget.css";

function Contact() {
  const [contactReason, setContactReason] = useState("");
  const [lorReason, setLorReason] = useState("");
  const [state, handleSubmit] = useForm("xzbowzlp");
  const [open, setOpen] = useState(false);

  if (state.succeeded) {
    window.scrollTo(0, 0);
    return (
      <div className="contactComponent">
        <div className="formSubmitMessage">
          <h1>Form Submitted Successfully!</h1>
          <p className="message">
            Dr. Hopfer will review your submission as soon as possible, and
            follow-up via email if applicable.
          </p>
          <LinkedButton path="/" text="Home Page" size="largeContactMessage" />
        </div>
      </div>
    );
  }

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="contactComponent">
      <h1>Contact Form</h1>
      <h2>You can use the following form to get in touch with us!</h2>
      <form onSubmit={handleSubmit} className="contactForm">
        <div className="doubleInputRow">
          <label>
            First Name
            <input
              type="text"
              name="first name"
              placeholder="first name"
              required
            />
          </label>

          <label>
            Last Name
            <input
              type="text"
              name="last name"
              placeholder="last name"
              required
            />
          </label>
        </div>

        <div className="doubleInputRow">
          <label>
            Phone Number
            <input
              type="text"
              name="phone number"
              placeholder="phone number"
              required
            />
          </label>

          <label>
            Email Address
            <input
              type="text"
              name="email address"
              placeholder="email address"
              required
            />
          </label>
        </div>

        <div className="singleInputRow">
          <label>
            Reason For Contact
            <select
              name="reason for contact"
              value={contactReason}
              onChange={(e) => setContactReason(e.target.value)}
              required
            >
              <option value="">reason for contact</option>
              <option value="letter of recommendation request">
                letter of recommendation request
              </option>
              <option value="joining the lab (graduate)">
                joining the lab (graduate)
              </option>
              <option value="joining the lab (undergraduate)">
                joining the lab (undergraduate)
              </option>
              <option value="media inquiry">media inquiry</option>
              <option value="general questions">general questions</option>
            </select>
          </label>
        </div>

        {contactReason === "joining the lab (graduate)" && (
          <div className="gradResearchNoteContainer">
            <p>
              Note to prospective graduate students: Thank you for your interest
              in joining my lab as a graduate student. If you are interested in
              learning more about UC Irvine's PhD in Public Health, please visit
              this{" "}
              <a
                href="https://publichealth.uci.edu/ph/_graduate/phd_in_ph"
                target="_blank"
                rel="noopener noreferrer"
              >
                link
              </a>
              .
            </p>
          </div>
        )}

        {contactReason === "joining the lab (undergraduate)" && (
          <div className="undergradResearchNoteContainer">
            <p>
              Note to students: My lab offers undergraduate research positions
              through PH 198/199. If you are interested in learning more about
              the program and its requirements, please visit this{" "}
              <a
                href="https://publichealth.uci.edu/ph/_undergraduate/ug_research"
                target="_blank"
                rel="noopener noreferrer"
              >
                link
              </a>
              .
            </p>
          </div>
        )}

        {contactReason === "letter of recommendation request" && (
          <div className="lorForm">
            <div className="lorRequestNoteContainer">
              <p>
                Note to students: Please do not ask me for a letter of
                recommendation if I do not know you well. In addition, I require
                a three-week notice to ensure I have adequate time to write a
                strong letter on your behalf.
              </p>
            </div>

            <div className="singleInputRow">
              <label>
                LOR Purpose
                <select
                  name="purpose of LOR"
                  value={lorReason}
                  onChange={(e) => setLorReason(e.target.value)}
                  required
                >
                  <option value="">select a purpose for this letter</option>
                  <option value="graduate school">
                    Graduate School: Masters or PhD
                  </option>
                  <option value="professional school">
                    Professional School: Medical, Dental, Pharmacy, PA, etc.
                  </option>
                  <option value="summer research programs">
                    Summer Research Programs
                  </option>
                  <option value="other">
                    Other: please specify letter purpose in message
                  </option>
                </select>
              </label>
            </div>

            <div className="singleInputRow">
              <label>
                Course History
                <textarea
                  name="course history"
                  placeholder="List any courses you have taken with me, including the term and the final grade received."
                  rows="5"
                  required
                />
              </label>
            </div>

            <div className="singleInputRow">
              <label>
                LOR Due Date
                <input type="date" name="LOR Due Date" required />
              </label>
            </div>
          </div>
        )}

        <div className="singleInputRow">
          <label>
            Message
            <textarea
              name="message"
              placeholder="Type your message here!"
              rows="10"
              required
            />
          </label>
        </div>

        <button
          className="contactFormSubmitButton"
          type="submit"
          disabled={state.submitting}
        >
          <p>Submit</p>
        </button>
      </form>
      <div className="zoomMeetingDiv">
        <p className="message">Prefer to speak one-on-one instead of email?</p>
        <p className="message secondLineMessage">Let's meet on Zoom!</p>
        <img
          src="https://1000logos.net/wp-content/uploads/2021/06/Zoom-logo.png"
          alt="Zoom logo"
        />
        <button className="calendlyModalTriggerButton" onClick={handleOpen}>
          <p>Schedule Meeting</p>
        </button>
      </div>

      <Modal open={open} onClose={handleClose}>
        <div className="calendlyModalContent">
          <button className="closeCalendlyModalButton" onClick={handleClose}>
            <p>x</p>
          </button>
          <CalendlyWidget />
        </div>
      </Modal>
    </div>
  );
}

export default Contact;
