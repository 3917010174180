import "./Slogan.css";

function Slogan() {
  return (
    <div className="sloganComponent">
      <div className="desktopSloganTextContainer sloganTextContainer">
        <p>Communication Matters for Promoting Health.</p>
      </div>
      <div className="mobileSloganTextContainer sloganTextContainer">
        <p>Communication Matters for</p>
        <p>Promoting Health.</p>
      </div>
      <div className="sloganSubheaderContainer">
        <p>Our research analyzes human communication behavior</p>
        <p>to guide public health outreach strategies.</p>
      </div>
    </div>
  );
}

export default Slogan;
