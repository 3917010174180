import { useState, useEffect } from "react";
import Publication from "./Publication/Publication";
import { publicationsList } from "../../constants/publications";
import "./CSS/Publications.css";

function Publications() {
  const [filter, setFilter] = useState("all");
  const [order, setOrder] = useState("descending");
  const [publications, setPublications] = useState([]);
  const [filteredPublications, setFilteredPublications] = useState([]);

  useEffect(() => {
    setPublications(publicationsList.sort(sortByYearDescending));
    setFilteredPublications(publicationsList.sort(sortByYearDescending));
  }, []);

  useEffect(() => {
    setFilteredPublications(
      publicationsList
        .filter((pub) => pub?.category !== undefined)
        .filter((pub) => pub?.category.includes(filter))
        .sort(
          order === "descending" ? sortByYearDescending : sortByYearAscending
        )
    );
  }, [filter, order]);

  const handleSort = () => {
    const newOrder = order === "descending" ? "ascending" : "descending";
    setOrder(newOrder);
    if (newOrder === "ascending") {
      setPublications(publicationsList.sort(sortByYearAscending));
      if (filter !== "all") {
        setFilteredPublications(
          publicationsList
            .filter((pub) => pub?.category !== undefined)
            .filter((pub) => pub?.category.includes(filter))
            .sort(sortByYearAscending)
        );
      }
    } else if (newOrder === "descending") {
      setPublications(publicationsList.sort(sortByYearDescending));
      if (filter !== "all") {
        setFilteredPublications(
          publicationsList
            .filter((pub) => pub?.category !== undefined)
            .filter((pub) => pub?.category.includes(filter))
            .sort(sortByYearDescending)
        );
      }
    }
  };

  const sortByYearAscending = (pubA, pubB) => {
    if (pubA.year !== pubB.year) {
      return pubA.year - pubB.year;
    } else {
      return [pubA.text, pubB.text].sort().indexOf(pubA.text) === 0 ? -1 : 1;
    }
  };

  const sortByYearDescending = (pubA, pubB) => {
    if (pubA.year !== pubB.year) {
      return pubB.year - pubA.year;
    } else {
      return [pubA.text, pubB.text].sort().indexOf(pubA.text) === 0 ? -1 : 1;
    }
  };

  return (
    <div className="publicationsComponent">
      <h1>Our Publications</h1>
      <div className="publicationsController">
        <div className="filterController">
          <p
            className={filter === "all" ? "selected" : ""}
            onClick={() => setFilter("all")}
          >
            All
          </p>
          <p
            className={filter === "vaccine communication" ? "selected" : ""}
            onClick={() => setFilter("vaccine communication")}
          >
            Vaccine Communication
          </p>
          <p
            className={filter === "covid-19" ? "selected" : ""}
            onClick={() => setFilter("covid-19")}
          >
            COVID-19
          </p>
          <p
            className={filter === "health disparities" ? "selected" : ""}
            onClick={() => setFilter("health disparities")}
          >
            Health Disparities
          </p>
          <p
            className={filter === "climate change" ? "selected" : ""}
            onClick={() => setFilter("climate change")}
          >
            Climate Change
          </p>
          <p
            className={filter === "community engagement" ? "selected" : ""}
            onClick={() => setFilter("community engagement")}
          >
            Community Engagement
          </p>
        </div>
        <div className="orderController">
          <p className="orderLabel">Sort By:</p>
          <p className="orderValue" onClick={handleSort}>
            year {order}
          </p>
        </div>
      </div>
      <div className="publicationsContainer">
        {filter === "all" &&
          publications.map((publication) => {
            return (
              <Publication
                publication={publication}
                number={
                  order === "descending"
                    ? publications.length - publications.indexOf(publication)
                    : publications.indexOf(publication) + 1
                }
                key={"_" + Math.random().toString(36).substr(2, 9)}
              />
            );
          })}
        {filter !== "all" &&
          filteredPublications.map((publication) => {
            return (
              <Publication
                publication={publication}
                number={
                  order === "descending"
                    ? filteredPublications.length -
                      filteredPublications.indexOf(publication)
                    : filteredPublications.indexOf(publication) + 1
                }
                key={"_" + Math.random().toString(36).substr(2, 9)}
              />
            );
          })}
      </div>
    </div>
  );
}

export default Publications;
