import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import hopfer from "./hopfer.jpeg";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./CSS/Footer.css";
import "./CSS/Footer_mobile.css";

function Footer() {
  const location = useLocation();

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -30;
    if (location.pathname === "/") {
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    } else {
      window.scrollTo({ top: yCoordinate + yOffset });
    }
  };

  return (
    <footer>
      <div className="footerLeftContainer">
        <p>Suellen Hopfer, PhD</p>
        <img src={hopfer} alt="Dr. Suellen Hopfer" />
      </div>

      <div className="footerLinksContainer">
        <p className="subheader">Quick Links</p>
        <Link to="/" className="footerLink">
          about
        </Link>
        <HashLink
          smooth
          to="/#research"
          scroll={(el) => scrollWithOffset(el)}
          className="footerLink"
        >
          research
        </HashLink>
        <Link to="/people" className="footerLink">
          lab members
        </Link>
        <Link to="/teaching" className="footerLink">
          teaching
        </Link>
        <Link to="/publications" className="footerLink">
          publications
        </Link>
        <Link to="/news" className="footerLink">
          news
        </Link>
        <Link to="/contact" className="footerLink">
          contact
        </Link>
      </div>

      <div className="footerContactContainer">
        <p className="subheader">Contact Us</p>
        <div className="textContainer">
          <div className="address">
            <p>University of California, Irvine</p>
            <p>Anteater Instruction & Research Bldg (AIRB) #2024</p>
            <p>Mail Code: 3957</p>
            <p>Irvine, CA 92697</p>
          </div>
          <div className="electronicCommunication">
            <div className="phoneEmailContainer">
              <a
                href="tel:9498246996"
                className="footerContactMethod footerPhoneContact"
              >
                <PhoneIcon fontSize="small" className="footerContactIcon" />
                <p className="footerContactText">949-824-6996</p>
              </a>
              <a
                href="mailto:shopfer@hs.uci.edu"
                className="footerContactMethod footerEmailContact"
              >
                <EmailIcon fontSize="small" className="footerContactIcon" />
                <p className="footerContactText">shopfer@hs.uci.edu</p>
              </a>
            </div>
            <div className="socialContainer">
              <p className="subheader">Follow Us</p>
              <div className="socialIconsContainer-footer">
                <a
                  href="https://twitter.com/HopferLab"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TwitterIcon fontSize="medium" className="socialIcon" />
                </a>
                <a
                  href="https://www.linkedin.com/in/suellen-hopfer-8ab57b28"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LinkedInIcon fontSize="medium" className="socialIcon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
