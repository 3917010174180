import "./CSS/Article.css";

function Article(props) {
  return (
    <div className="articleComponent">
      <div className="contentContainer">
        <div className="bubbleContainer">
          <div className="sourceBubble bubble">
            <p className="newsSource">{props.source}</p>
          </div>
          <div className="dateBubble bubble">
            <p>{props.date}</p>
          </div>
        </div>
        <a
          href={props.link}
          target="_blank"
          rel="noopener noreferrer"
          className="articleLink"
        >
          <h3>{props.title}</h3>
        </a>
        <p className="intro">{props.intro}</p>
      </div>
      <div className="imageContainer">
        <img src={props.image} alt="not available" className="image" />
      </div>
    </div>
  );
}

export default Article;
