import graphic from "./graphic-02.png";
import "./ResearchGraphic.css";

function ResearchGraphic() {
  return (
    <img
      className="researchGraphic"
      src={graphic}
      alt="research breakdown graphic"
    />
  );
}

export default ResearchGraphic;
