import { Link } from "react-router-dom";
import "./LinkedButton.css";

function LinkedButton({ path, text, size = "normal" }) {
  return (
    <Link to={path} className="navLinkButton" id={`${size}LinkButton`}>
      <p>{text}</p>
    </Link>
  );
}

export default LinkedButton;
