import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CSS/ResearchCarouselComponent.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function ResearchCarouselComponent({ images }) {
  const renderArrowPrev = (clickHandler, hasPrev, label) => {
    return (
      <span className="arrowButtonLeft arrowButton" onClick={clickHandler}>
        <ArrowBackIcon />
      </span>
    );
  };

  const renderArrowNext = (clickHandler, hasNext, label) => {
    return (
      <span className="arrowButtonRight arrowButton" onClick={clickHandler}>
        <ArrowForwardIcon />
      </span>
    );
  };

  return (
    <div className="carousel-wrapper-research">
      <Carousel
        infiniteLoop
        useKeyboardArrows
        autoPlay={window.screen.width >= 1024 ? false : true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={window.screen.width >= 1024 ? true : false}
        renderArrowPrev={
          window.screen.width >= 1024 ? renderArrowPrev : undefined
        }
        renderArrowNext={
          window.screen.width >= 1024 ? renderArrowNext : undefined
        }
        className="carousel-research"
      >
        {images.map((image) => {
          return (
            <div key={images.indexOf(image)}>
              <img src={image} alt="" className="studyImage-carousel" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
