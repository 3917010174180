import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import Collapsible from "react-collapsible";
import ResearchCarouselComponent from "./Carousel/ResearchCarouselComponent";
import ProjectPublication from "./ProjectPublication/ProjectPublication";
import { studies } from "../../constants/studies";
import "./CSS/Research.css";
import "./CSS/Research_mobile.css";

function Research(props) {
  const { focus } = useParams();
  const researchFocus = focus
    .split(/(?=[A-Z])/)
    .map((str) => str[0].toUpperCase() + str.slice(1))
    .join(" ");

  return (
    <div className="researchComponent">
      <h1>{researchFocus} Research</h1>
      {studies[focus].map((study) => {
        return (
          <div
            className="researchArea"
            key={"_" + Math.random().toString(36).substr(2, 9)}
          >
            <Collapsible
              trigger={
                <div className="researchAreaTrigger">
                  <p>{study.title}</p>
                  <button>
                    <p>+</p>
                  </button>
                </div>
              }
            >
              <div className="researchAreaContent">
                <div className="projectDescription">
                  {typeof study.description === "string" ? (
                    <p>{study.description}</p>
                  ) : (
                    study.description.map((paragraph) => {
                      return (
                        <p
                          className="projectText"
                          key={"_" + Math.random().toString(36).substr(2, 9)}
                        >
                          {paragraph}
                        </p>
                      );
                    })
                  )}
                </div>

                {study?.links?.length && (
                  <div className="projectLinks">
                    {study?.links.map((link) => {
                      return (
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={"_" + Math.random().toString(36).substr(2, 9)}
                        >
                          {link.text}
                        </a>
                      );
                    })}
                  </div>
                )}

                {study?.publications?.length && (
                  <div className="projectPublicationsContainer">
                    {study?.publications.map((pub) => {
                      return (
                        <ProjectPublication
                          text={pub.text}
                          url={pub.url}
                          key={"_" + Math.random().toString(36).substr(2, 9)}
                        />
                      );
                    })}
                  </div>
                )}

                {study?.image && (
                  <div className="projectImages">
                    {study.image && !Array.isArray(study.image) ? (
                      <img
                        src={study.image}
                        className="studyImage"
                        alt="study"
                      />
                    ) : (
                      <ResearchCarouselComponent images={[...study.image]} />
                    )}
                  </div>
                )}

                {study?.video && (
                  <div className="projectVideo">
                    <ReactPlayer
                      className="react-player"
                      url={study.video}
                      controls
                    />
                  </div>
                )}
              </div>
            </Collapsible>
          </div>
        );
      })}
    </div>
  );
}

export default Research;
