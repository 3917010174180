import { HashLink } from "react-router-hash-link";
import "./HashLinkedButton.css";

function HashLinkedButton({ path, text, size = "normal" }) {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -30;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <HashLink
      smooth
      to={path}
      className="navHashLinkButton"
      id={`${size}NavHashLinkButton`}
      scroll={(el) => scrollWithOffset(el)}
    >
      <p>{text}</p>
    </HashLink>
  );
}

export default HashLinkedButton;
