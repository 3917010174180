import { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";

const desktopStyles = {
  minWidth: "700px",
  width: "40vw",
  minHeight: "550px",
  height: "75vh",
};

const tabletStyles = {
  width: "90vw",
  maxWidth: "750px",
  height: "70vh",
};

const mobileStyles = {
  minWidth: "250px",
  width: "90vw",
  height: "70vh",
  minHeight: "400px",
};

function CalendlyWidget() {
  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (window.screen.width >= 1024) {
      setStyles(desktopStyles);
    } else if (1024 > window.screen.width && window.screen.width > 480) {
      setStyles(tabletStyles);
    } else if (window.screen.width <= 480) {
      setStyles(mobileStyles);
    } else {
      console.log("current screen width is between 480 and 1024 px");
    }
  }, []);

  return (
    <InlineWidget
      url="https://calendly.com/shopfer/15-minute-meeting"
      styles={styles}
      pageSettings={{
        backgroundColor: "ffffff",
        hideEventTypeDetails: false,
        hideLandingPageDetails: false,
        primaryColor: "00a2ff",
        textColor: "4d5055",
      }}
    />
  );
}

export default CalendlyWidget;
