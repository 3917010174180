import "./CSS/Course.css";

function Course({ course }) {
  return (
    <div className="CourseComponent">
      <div className="courseInfo">
        <div className="bubbleContainer">
          <div className="courseCodeContainer courseBubble">
            <p>{course.code}</p>
          </div>
          <div className="courseUnitsContainer courseBubble">
            <p>{course.units} units</p>
          </div>
        </div>
        <div className="courseTitleContainer">
          <h3>{course.title}</h3>
        </div>
        <div className="courseDescriptionContainer">
          <p>{course.description}</p>
        </div>
      </div>
    </div>
  );
}

export default Course;
