import { useState } from "react";
import "./CSS/People.css";
import Profile from "./Profile/Profile";
import { profiles } from "../../constants/profiles";

function People() {
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState("");

  const handleClose = () => setOpen(false);

  const handleHeadshotClick = (person) => {
    const newFocus = profiles.find((profile) => profile.alt === person);
    if (newFocus) {
      setFocus(newFocus);
      setOpen(true);
    }
  };

  return (
    <div className="PeopleComponent">
      <h1>Our Team</h1>
      <div className="profileContainer">
        {profiles.filter((profile) => !profile.type).map((profile) => {
          return (
            <Profile
              profile={profile}
              focus={focus}
              open={open}
              handleHeadshotClick={handleHeadshotClick}
              handleClose={handleClose}
              key={"_" + Math.random().toString(36).substr(2, 9)}
            />
          );
        })}
      </div>
      <h1 className="alumniSectionHeading">Alumni Members</h1>
      <div className="alumniProfileContainer">
        {profiles.filter(profile => profile?.type === "alumni").map((profile) => {
          return (
            <Profile
              profile={profile}
              focus={focus}
              open={open}
              handleHeadshotClick={handleHeadshotClick}
              handleClose={handleClose}
              key={"_" + Math.random().toString(36).substr(2, 9)}
            />
          );
        })}
      </div>
      <h1 className="collaboratorSectionHeading">Collaborators</h1>
      <div className="collaboratorProfileContainer">
        {profiles.filter((profile) => profile?.type === "collaborator").map((profile) => {
          return (
            <Profile
              profile={profile}
              focus={focus}
              open={open}
              handleHeadshotClick={handleHeadshotClick}
              handleClose={handleClose}
              key={"_" + Math.random().toString(36).substr(2, 9)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default People;
