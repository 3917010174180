import "./Teaching.css";
import { courses } from "../../constants/courses";
import Course from "./Course/Course";

function Teaching() {
  return (
    <div className="teachingComponent">
      <h1>Teaching</h1>
      <div className="undergradCoursesContainer">
        <h2>Undergraduate Courses</h2>
        <div className="coursesContainer">
          {courses.undergraduate.map((course) => (
            <Course
              course={course}
              key={"_" + Math.random().toString(36).substr(2, 9)}
            />
          ))}
        </div>
      </div>
      <div className="gradCoursesContainer">
        <h2>Graduate Courses</h2>
        <div className="coursesContainer">
          {courses.graduate.map((course) => (
            <Course
              course={course}
              key={"_" + Math.random().toString(36).substr(2, 9)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Teaching;
