import LinkedButton from "../../../../buttons/LinkedButton/LinkedButton";
import "./GifBox.css";

function GifBox({ title, researchArea, gif }) {
  return (
    <div className="GifBox">
      <img src={gif} alt={title} className="GIF" />
      <p className="title">{title}</p>
      <LinkedButton
        path={`/research/${researchArea}`}
        text="View Work"
        size="gifBox"
      />
    </div>
  );
}

export default GifBox;
