import "./ProjectPublication.css";

function ProjectPublication({ text, url }) {
  return (
    <div className="projectPublication">
      <div className="projectPublicationTextContainer">
        <p>{text}</p>
      </div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        <p>Link</p>
      </a>
    </div>
  );
}

export default ProjectPublication;
