import GifBox from "./GifBox/GifBox";
import researchAreas from "../../../../constants/researchAreas";
import "./CSS/GifBoxContainer.css";

function GifBoxContainer() {
  return (
    <div className="GifBoxContainer">
      {researchAreas.map(({ title, researchArea, image }) => {
        return (
          <GifBox
            title={title}
            researchArea={researchArea}
            gif={image}
            key={"_" + Math.random().toString(36).substr(2, 9)}
          />
        );
      })}
    </div>
  );
}

export default GifBoxContainer;
