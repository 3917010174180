import laMandate from "./Images/laMandate.webp";
import childVaccine from "./Images/childVaccine.jpeg";
import childVaccineQuestions from "./Images/childVaccineQuestions.jpeg";
import ocLatino from "./Images/ocLatino.webp";
import garciaFellowship from "./Images/garciaFellowship.jpeg";
import healthInfo from "./Images/healthInfo.jpeg";
import duongFellowship from "./Images/duongFellowship.png";
import childVaccine2 from "./Images/childVaccine2.webp";
import vaccineClinic from "./Images/vaccineClinic.webp";

export const newsArticles = [
  {
    title: "Why Los Angeles Delayed Enforcing Its Student Vaccine Mandate",
    link: "https://www.nytimes.com/2021/12/18/us/los-angeles-vaccine-mandate-delayed.html",
    source: "The New York Times",
    intro:
      "By at least one important measure, the Los Angeles school district's plan to require vaccinations for students 12 and older has been a success: Three months after the mandate was announced, almost 90 percent of those students had complied. Nevertheless, the Los Angeles Unified School District — the first major district in the United States to call for such a mandate — decided this week to slow things down. Board members voted 6-1 not to enforce the vaccine deadline, originally scheduled for Jan. 10, until fall 2022.",
    date: "December 18, 2021",
    image: laMandate,
  },
  {
    title:
      "UCI-led study identifies top concerns of parents in vaccinating their adolescent children",
    link: "https://news.uci.edu/2022/04/05/uci-led-study-identifies-top-concerns-of-parents-in-vaccinating-their-adolescent-children/",
    source: "UCI News",
    intro:
      "Confidence in health authorities' vaccine recommendations, the social benefit and a sense of collective responsibility to protect oneself and others were ranked among the top motivators of Southern California parents willing to vaccinate their adolescent children against COVID-19. According to a recent study led by the University of California, Irvine researchers, concerns over potential side effects, and skepticism over vaccine safety and efficacy were some of the chief issues expressed by parents hesitant to vaccinate their adolescent children against the virus.",
    date: "April 5, 2021",
    image: childVaccine,
  },
  {
    title:
      "Got questions about the Covid vaccine for kids? Here's a helpful FAQ for parents",
    link: "https://www.mother.ly/health-wellness/kids-covid-vaccine-info/",
    source: "Motherly",
    intro:
      "The Centers for Disease Control and Prevention (CDC) has just approved vaccines for kids 5 to 11 this week, making shots now finally available for this age range—and we know that parents have questions about what that means for their kiddos.",
    date: "November 4, 2021",
    image: childVaccineQuestions,
  },
  {
    title:
      "OC's Latino Community Still Behind on COVID-19 Vaccinations as Fears of a Winter Wave Surface",
    link: "https://voiceofoc.org/2021/11/ocs-latino-community-still-behind-on-covid-19-vaccinations-as-fears-of-a-winter-wave-surface/",
    source: "Voice of OC",
    intro:
      "While public health experts fear a potential COVID-19 spike this Winter, Latinos in Orange County and across the state still have the lowest vaccination rates against the virus that has impacted the community the most. It's a gap that's persisted since the shots were rolled out at the beginning of this year.",
    date: "November 1, 2021",
    image: ocLatino,
  },
  {
    title: "Samantha Garcia, PhD Candidate, Selected for F31 Fellowship",
    link: "https://reporter.nih.gov/search/z0wyQUk7AkirmH5GqQ5bbA/project-details/10246856",
    source: "NIH RePORT",
    intro:
      "Samantha Garcia, a member of Hopfer Labs, was awarded the F31 fellowship by the National Institute on Minority Health and Health Disparities. This fellowship is designed to provide health-related research experience to students pursuing a doctoral research degree. Garcia intends to use this fellowship to fund her research on the factors associated with HPV vaccine hesitancy among young adult Mexican-American women.",
    date: "October 26, 2021",
    image: garciaFellowship,
  },
  {
    title:
      "UCI-led study investigates the sources that Latina, Vietnamese women turn to for health information",
    link: "https://www.newswise.com/articles/uci-led-study-investigates-the-sources-that-latina-vietnamese-women-turn-to-for-health-information",
    source: "News Wise",
    intro:
      "Latina and Vietnamese women are disproportionately impacted by cervical cancer-causing human papillomavirus (HPV), a common but preventable viral infection of the reproductive tract. In addition to facing a greater burden of disease, Latina and Vietnamese women are also known to underutilize the HPV vaccine, which is an effective cervical cancer prevention measure.",
    date: "October 1, 2021",
    image: healthInfo,
  },
  {
    title: "Theresa Duong, PhD Candidate, Chosen for Fletcher Jones Fellowship",
    link: "https://publichealth.uci.edu/ph/_news_events/featured_news/theresa-duong-phd-candidate-chosen-for-campus-wide-fellowship",
    source: "UCI Public Health",
    intro:
      "Theresa (Huong) Duong, a member of Hopfer Lab, was selected as the UCI recipient for the Fletcher Jones Fellowship for the entire UCI campus. The Fletcher Jones fellowship is a highly prestigious award that provides a doctoral student with a dissertation completion fellowship to be used as a stipend. Each campus may make one award, and the recipient is chosen by a committee constituted by the Graduate Dean.",
    date: "September 15, 2021",
    image: duongFellowship,
  },
  {
    title: "Younger kids: To vax or not to vax? That is the question",
    link: "https://www.ocregister.com/2021/06/27/younger-kids-to-vax-or-not-to-vax-that-is-the-question/",
    source: "OC Register",
    intro:
      "Suellen Hopfer, assistant professor of public health at UC Irvine, held focus groups with scores of Orange County families to understand their thinking on vaccinating children. Fewer than half — 45%  — said they'd get their adolescent children vaccinated. Nearly as many — 43% — were unsure. And 11% said they definitely would not. “The younger the children were, the more hesitant the parents were to have them vaccinated,” Hopfer said.",
    date: "June 29, 2021",
    image: childVaccine2,
  },
  {
    title:
      "Efforts to Vaccinate People Are Stretching OC's Community Groups, Clinics Thin",
    link: "https://voiceofoc.org/2021/05/efforts-to-vaccinate-people-are-stretching-ocs-community-groups-and-clinics-thin/",
    source: "Voice of OC",
    intro:
      "Orange County's community groups and local clinics are being stretched thin as they work together to vaccinate as many residents as possible ahead of the slated June 15 statewide reopening.",
    date: "May 4, 2021",
    image: vaccineClinic,
  },
];
