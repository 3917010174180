import "./CSS/ButtonBox.css";
import HashLinkedButton from "../../buttons/HashLinkedButton/HashLinkedButton";
import LinkedButton from "../../buttons/LinkedButton/LinkedButton";

function ButtonBox() {
  return (
    <div className="button-container">
      <HashLinkedButton path="/#research" text="research" size="large" />
      <LinkedButton path="/team" text="team" size="large" />
      <LinkedButton path="/contact" text="contact" size="large" />
    </div>
  );
}

export default ButtonBox;
