import "./Publication.css";

function Publication({ publication, number }) {
  return (
    <div
      className="publication"
      key={"_" + Math.random().toString(36).substr(2, 9)}
    >
      <div className="numberContainer">
        <div className="numberBox">
          <p>{number}</p>
        </div>
      </div>
      <div className="textContainer">
        <p>{publication.text}</p>
      </div>
      <div className="buttonContainer">
        {publication.link && (
          <a
            href={publication.link}
            target="_blank"
            rel="noopener noreferrer"
            className="linkButton"
          >
            <p>Link</p>
          </a>
        )}
      </div>
    </div>
  );
}

export default Publication;
