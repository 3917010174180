import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function CarouselComponent({ images }) {
  return (
    <div className="carousel-wrapper">
      <Carousel
        infiniteLoop
        useKeyboardArrows
        autoPlay
        showThumbs={false}
        showStatus={false}
      >
        {images.map((image) => {
          return (
            <div key={images.indexOf(image)}>
              <img src={image} alt="" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
