import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Research from "./components/Research/Research";
import People from "./components/People/People";
import Teaching from "./components/Teaching/Teaching";
import Publications from "./components/Publications/Publications";
import News from "./components/News/News";
import Contact from "./components/Contact/Contact";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="research/:focus" element={<Research />} />
          <Route path="team" element={<People />} />
          <Route path="teaching" element={<Teaching />} />
          <Route path="publications" element={<Publications />} />
          <Route path="news" element={<News />} />
          <Route path="contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
