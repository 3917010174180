import "./Home.css";
import PhotoBox from "./PhotoBox/PhotoBox";
import Slogan from "./Slogan/Slogan";
import ButtonBox from "./ButtonBox/ButtonBox";
import Mission from "./Mission/Mission";
import ResearchGraphic from "./ResearchGraphic/ResearchGraphic";
import ResearchAreas from "./ResearchAreas/ResearchAreas";
import AboutHopfer from "./AboutHopfer/AboutHopfer";

function Home() {
  return (
    <div className="homePage">
      <PhotoBox />
      <Slogan />
      <ButtonBox />
      <Mission />
      <ResearchGraphic />
      <ResearchAreas />
      <AboutHopfer />
    </div>
  );
}

export default Home;
