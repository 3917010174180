import "./ExternalLinkedButton.css";

function ExternalLinkedButton({ path, text, size = "normal" }) {
  return (
    <a
      href={path}
      className="navExternalLinkButton"
      id={`${size}NavExternalLinkButton`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <p>{text}</p>
    </a>
  );
}

export default ExternalLinkedButton;
