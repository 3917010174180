import SectionTitle from "../SectionTitle/SectionTitle";
import GifBoxContainer from "./GifBoxContainer/GifBoxContainer";
import "./ResearchAreas.css";

function ResearchAreas() {
  return (
    <div id="research">
      <SectionTitle text="Research" />
      <h1>Areas of Investigation</h1>
      <GifBoxContainer />
    </div>
  );
}

export default ResearchAreas;
